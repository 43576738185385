.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: large;
}

.stretch__holder {
  background-color: #FF9F1C;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0;
}

.stretch__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stretch__header h1 {
  display: inline;
  margin: 16px 5%;
}

.stretch__table {
  background-color: #41EAD4;
}
